export const KnightIcon = () => {
    return <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
        viewBox="0 0 511.989 511.989" >
        <path style={{ fill: '#ED5564' }} d="M274.445,39.76c-17.687-19.312-41.842-29.102-71.779-29.102c-26.921,0-47.296,7.273-60.593,21.602
	c-23.609,25.46-20.437,67.53-17.64,104.646c2.453,32.437,4.765,63.069-11.344,70.061c-5.234,2.273-10.641,3.422-16.078,3.422
	c-20.671,0-40.202-16.523-52.937-30.39c-14.703-15.992-24.031-32.335-24.125-32.491c-2.688-4.75-8.531-6.711-13.531-4.539
	c-5.016,2.172-7.562,7.773-5.922,12.984c0.328,1.07,8.422,26.468,26.187,52.132c24.375,35.234,55.28,53.858,89.358,53.858
	c8.203,0,16.687-1.086,25.203-3.219c22.921-5.726,38.671-18.218,48.155-38.194c7.828-16.476,10.078-35.718,12.062-52.695
	c4.266-36.655,8.14-50.507,32.062-50.507c21.156,0,45.28,27.164,52.78,37.617c2.594,3.633,7.188,5.266,11.484,4.094
	c4.312-1.18,7.438-4.914,7.828-9.359C305.867,146.852,311.273,79.962,274.445,39.76z"/>
        <g style={{ opacity: 0.2 }}>
            <path style={{ fill: '#FFFFFF' }} d="M142.073,42.932c13.296-14.335,33.671-21.601,60.593-21.601c29.937,0,54.092,9.789,71.779,29.093
		c25.844,28.21,30.891,69.545,31.516,92.686c0.516-18.258-0.953-69.991-31.516-103.35c-17.687-19.312-41.842-29.102-71.779-29.102
		c-26.921,0-47.296,7.273-60.593,21.602c-16.828,18.148-20.046,44.733-19.5,71.952C123.105,80.829,127.448,58.697,142.073,42.932z"
            />
            <path style={{ fill: '#FFFFFF' }} d="M1.294,158.344c1.094-2.031,2.859-3.727,5.125-4.711c5-2.172,10.843-0.211,13.531,4.539
		c0.094,0.156,9.422,16.507,24.125,32.5c12.734,13.859,32.265,30.39,52.937,30.39c5.438,0,10.844-1.156,16.078-3.429
		c11.375-4.938,13.562-21.671,13-42.507c-0.5,15.734-3.64,27.781-13,31.843c-5.234,2.273-10.641,3.422-16.078,3.422
		c-20.671,0-40.202-16.523-52.937-30.39c-14.703-15.992-24.031-32.335-24.125-32.491c-2.688-4.75-8.531-6.711-13.531-4.539
		c-5.016,2.172-7.562,7.773-5.922,12.984C0.544,156.133,0.81,156.969,1.294,158.344z"/>
        </g>
        <path style={{ fill: '#CCD1D9' }} d="M494.426,435.457c-9.734-11.125-24.953-22.984-46.437-36.156v-66.703l10.624-128.192
	c0.062-0.727,0.047-1.461-0.031-2.18c-2.218-18.008-17.702-36.789-42.498-51.546c-23.859-14.211-52.453-22.359-78.437-22.359
	c-17.797,0-33.812,3.695-47.64,10.992c-23.422,12.359-41.187,26.671-52.78,42.538c-10.515,14.367-16.359,30.484-17.359,47.882
	c-1.797,31.039,11.984,61.554,23.062,86.07c7.828,17.326,15.905,35.232,13.453,43.967c-11,39.25-40.796,81.625-41.093,82.047
	c-2.406,3.406-2.625,7.904-0.531,11.514s6.094,5.672,10.25,5.281c13.843-1.344,27.265-2,39.905-2
	c64.812,0,100.811,17.469,127.092,30.219c16.016,7.781,29.844,14.5,44.78,14.5c13.594,0,27.874-2.047,39.171-5.594
	c17-5.312,27.219-13.812,30.359-25.219C509.301,459.674,505.301,447.863,494.426,435.457z"/>
        <path style={{ fill: '#434A54' }} d="M447.989,203.523l-117.326,63.131v21.336l106.654,74.654c0,0,63.999-59.029,63.999-85.311
	C501.316,251.037,447.989,203.523,447.989,203.523z"/>
        <path style={{ fill: '#E6E9ED' }} d="M482.66,223.522c-13.609-15.476-27.015-27.46-27.577-27.96c-3.344-2.984-8.203-3.555-12.156-1.43
	l-64.296,34.593c-0.016,0.016-0.047,0.031-0.062,0.039l-52.968,28.5c-3.453,1.859-5.609,5.469-5.609,9.391v21.336
	c0,3.484,1.703,6.75,4.547,8.734l106.67,74.67c1.844,1.281,3.984,1.938,6.109,1.938c2.609,0,5.203-0.969,7.234-2.844
	c0.672-0.609,16.577-15.328,32.749-33.562c14.891-16.795,34.688-41.248,34.688-59.592
	C511.988,263.56,502.394,245.959,482.66,223.522z M446.317,216.538c5,4.765,12.765,12.468,20.452,21.226
	c9.656,10.992,16.797,21.054,20.656,28.89h-23.453l-31.983-42.406L446.317,216.538z M396.49,321.053l24.828-33.062h14.983
	l-31.483,38.891L396.49,321.053z M348.303,287.318c1.156,0.422,2.391,0.672,3.688,0.672h42.641l-15.641,20.812L348.303,287.318z
	 M380.959,251.702l12.375,14.953h-40.172L380.959,251.702z M412.99,234.475l24.265,32.179h-16.249l-20.891-25.257L412.99,234.475z
	 M341.319,277.334v-4.312l1.234-0.656C341.772,273.849,341.319,275.537,341.319,277.334c0,2.609,0.953,5,2.516,6.875l-2.516-1.781
	V277.334z M461.441,322.646c-9.515,10.748-19.14,20.404-25.155,26.264l-13.952-9.766l41.404-51.154h23.328
	C483.394,295.115,476.066,306.146,461.441,322.646z"/>
        <path style={{ fill: '#434A54' }} d="M351.99,277.334c0,11.781-9.546,21.312-21.327,21.312s-21.343-9.531-21.343-21.312
	c0-11.789,9.562-21.343,21.343-21.343S351.99,265.545,351.99,277.334z"/>
        <path style={{ opacity: 0.1 }} d="M447.989,399.301v29.75l23.155-14.281
	C464.426,409.801,456.738,404.676,447.989,399.301z"/>
    </svg>
}